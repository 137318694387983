/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

var scrollTop = 0;
var bannerHeight;
var propVideo;
var preScroll = 0; 

var allItems, itemGrid, grid, rowHeight, rowGap, rowSpan;

/*
function resizeGridItem(item){
  grid = document.getElementsByClassName("grid")[0];
  rowHeight = parseInt(window.getComputedStyle(grid).getPropertyValue('grid-auto-rows'));
  rowGap = parseInt(window.getComputedStyle(grid).getPropertyValue('grid-row-gap'));
  rowSpan = Math.ceil((item.querySelector('.content').getBoundingClientRect().height+rowGap)/(rowHeight+rowGap));
    item.style.gridRowEnd = "span "+rowSpan;
  
  console.log(rowSpan);
}

function resizeAllGridItems(){
  allItems = document.getElementsByClassName("item");
  for(x=0;x<allItems.length;x++){
    resizeGridItem(allItems[x]);
  }
}

function resizeInstance(instance){
	item = instance.elements[0];
  resizeGridItem(item);
}
*/

function amountscrolled(){
  var winheight = jQuery(window).height()
  var docheight = jQuery(document).height()
  var scrollTop = jQuery(window).scrollTop()
  var trackLength = docheight - winheight
  var pctScrolled = Math.floor(scrollTop/trackLength * 100) // gets percentage scrolled (ie: 80 NaN if tracklength == 0)
 // console.log(pctScrolled + '% scrolled')
  return pctScrolled;
}

/*
function getScrollPercent() {
    var h = document.documentElement, 
        b = document.body,
        st = 'scrollTop',
        sh = 'scrollHeight';
    return (h[st]||b[st]) / ((h[sh]||b[sh]) - h.clientHeight) * 100;
}
*/


(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages


        if(jQuery('.wp-pagenavi').length>0){

            jQuery('.wp-pagenavi .page, .wp-pagenavi  .current,  .wp-pagenavi  .extend, .wp-pagenavi  .nextpostslink, .wp-pagenavi  .previouspostslink').each(function(i){

              jQuery(this).width(jQuery(this).height() ) ;
        
            }); 
        }
        //icon-search

 
 
        resize();

        


        jQuery('.liSub > a').on('click', function(ev){
          ev.preventDefault();
        });

 


      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    'error404':{
      init:function(){
 
      }
    },
    // Home page
    'page_template_template_home': {
      init: function() {
        // JavaScript to be fired on the home page

      //interstitialSection
      
       $.scrollify({
          section : ".slide_proy",
          scrollbars: false,
          interstitialSection :"footer",
          before:function(index, sections){
            //alert(index);
           // var vid = jQuery('.slide_proy').eq[index+1].find('video');
           // vid.pause();
          },
          after:function(index, sections){
            var vid = sections[index];
            
              vid = document.querySelector('#slidepro-'+index +" video");
              if(vid){
                  vid.play();
              }
            
             
           jQuery('#estoy_proy').text(index+1);

         //  var vid = jQuery('.slide_proy').eq[index+1].find('video');
         //  vid.play();

            /*
var vid = document.getElementById("myVideo");

function playVid() {
    vid.play();
}

function pauseVid() {
    vid.pause();
}
            */


          },
          afterRender:function(){

          }
        });


            $('#bot_next_proy').on('click', function(ev){
              ev.preventDefault();  
              $.scrollify.next();
    
            });
 
          //$.scrollify.next()

        

      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'single_product': {
      init: function() {
        // JavaScript to be fired on the about us page

            


      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.

window.onload=function(){


  resize();
  

 /*
  jQuery('html').scrollWithEase({
    frameRate: 60,
    animationTime: 500,
    stepSize: 20,
    pulseAlgorithm: true,
    pulseScale: 8,
    pulseNormalize: 1,
    accelerationDelta: 700,
    accelerationMax: 5,
    keyboardSupport: true,
    arrowScroll: 50   
  });

*/
  jQuery('.bot_movil').on('click', function(ev){
    ev.preventDefault();
    jQuery(this).toggleClass('abierto'); 
    //jQuery('.nav-primary').slideToggle('normal'); 
    jQuery('.nav-primary').toggleClass('menu_cerrado');
    jQuery('.banner').toggleClass('desplegado');

  });

  if(jQuery(window).width() < 1024 ){
 


      jQuery('.tinc_sub').on('click', function(ev){ 
        ev.preventDefault();
        /*  
        jQuery(this).next().slideToggle('normal', function(){
          
        });
        */

        if(jQuery(this).hasClass('open_menu')){
          jQuery(this).next().slideUp('normal', function(){
            jQuery('.tinc_sub').removeClass('open_menu');
          });
        }else{
           
          jQuery(this).next().slideDown('normal', function(){

            jQuery('.tinc_sub').addClass('open_menu');
          });
        }

      });

  }

 

if(jQuery(window).width()>992){

    jQuery('#bot_busca_header').on('click', function(ev){
      ev.preventDefault();
      //jQuery('.out_buscador').slideToggle(400); 
      jQuery('.buscador_up').toggleClass('sacaBusca');
 
      jQuery('.page-template-template-home .banner').toggleClass('fon_blanco_banner');
  });

}


 

if(jQuery(window).width() < 768 ){
 

}

/*
m=(T1-T0)/(D1-D0)
n=T0-D0*m 

  var winheight = jQuery(window).height()
  var docheight = jQuery(document).height() 
  var trackLength = docheight - winheight

*/

var winheight = jQuery(window).height();
var docheight = jQuery(document).height() ;
var trackLength = docheight - winheight;

jQuery('.parallax_vert').each(function(){

  //console.log(jQuery(this).data('inipos'));
 // console.log(jQuery(this).data('finpos'));
  var iPos = jQuery(this).data('inipos');
  var fPos = jQuery(this).data('finpos');
  var eme = (fPos-iPos)/(100);
  var ene = iPos ;
  jQuery(this).data('m', eme );
  jQuery(this).data('n',  ene) ;

});

/*
T0=D0*m +n
T1=D1*m +n

m=(T1-T0)/(D1-D0)
n=T0-D0*m
*/

jQuery('.parallax_per').each(function(){

  //console.log(jQuery(this).data('inipos'));
  //console.log(jQuery(this).data('finpos'));
  var iPos = jQuery(this).data('iniposy');
  var fPos = jQuery(this).data('finposy');


  var iniPerY = jQuery(this).css('transform').split(','); //jQuery(this).data('inipery');

  iniPerY = parseFloat(iniPerY[5]);

  console.log(" IPOS " + iPos +" FPOS "+fPos);

  var eme = (fPos-iPos)/(100);
  var ene = iPos; 

  console.log(" eme " + eme +" ene " + ene);

  jQuery(this).data('m', eme );
  jQuery(this).data('n',  ene) ;
  jQuery(this).data('inipery',  iniPerY) ;

});
 
 
jQuery('.video_portada').each(function(i){



  //jQuery(this).height(jquery(this).width() / prop);
  
 
});

jQuery('.wp-block-embed-vimeo iframe').each(function(i){
  console.log(jQuery(this))
  var anch = jQuery(this).attr('width');
  var alt = jQuery(this).attr('height');
  var prop = anch/alt;
  jQuery(this).height(jQuery(this).width() / prop);
});





//window.onload = resizeAllGridItems();
//window.addEventListener("resize", resizeAllGridItems);

allItems = document.getElementsByClassName("itemGrid");
for(x=0;x<allItems.length;x++){
  imagesLoaded( allItems[x], resizeInstance);
}


resize();

} //onLoad


function resize(){

  jQuery('.wp-block-embed-vimeo iframe').each(function(i){
    console.log(jQuery(this))
    var anch = jQuery(this).attr('width');
    var alt = jQuery(this).attr('height');
    var prop = anch/alt;
    jQuery(this).height(jQuery(this).width() / prop);
  });

  if(jQuery('.in_caja')){
    var resto = (jQuery(window).width() - jQuery('.container').outerWidth())/2 ; 
    jQuery('.in_caja').width( jQuery('.in_caja').parent().outerWidth() + resto +15  )
  }


  bannerHeight = jQuery('.banner').outerHeight(true) ;

  if(jQuery('.cont_video')){
  
      propVideo = jQuery('.cont_video iframe').attr('width')/jQuery('.cont_video iframe').attr('height');
 
    jQuery('.cont_video iframe').height( jQuery('.cont_video iframe').width() / propVideo ) ;

      

  }
var bh  = jQuery('.banner').outerHeight(true)  ;
console.log("BANNER HEIGHT "+ bh );

  jQuery('.sep_menu').height( bh  );


  jQuery('.baix_text').width(jQuery('.caja_dire').outerWidth() );
  jQuery('.baix_text').height(jQuery('.caja_dire').outerHeight() );

  jQuery('.img_marg_up').each(function(i){
    jQuery(this).css('margin-top', jQuery(this).height()/2 * -1 );
  });


  jQuery('.pad_50_img').css('padding-bottom', (jQuery('.img_marg_up').outerHeight()/2  )+"px" );
 
  if(jQuery(document).width() > 1024){
      if(jQuery('.caja_sobre_foto').outerHeight()>=jQuery('.cont_img_collect').outerHeight() ){
        //jQuery('.cont_img_collect').height(jQuery('.caja_sobre_foto').outerHeight() + 100 );
        jQuery('.cont_img_collect').css('min-height', jQuery('.caja_sobre_foto').outerHeight() + 100 );
      }

  }

  jQuery('.prod_image').height(jQuery('.prod_texto').outerHeight());
 

  if(jQuery('.caja_sobre_foto').outerHeight() > jQuery('.cont_img_collect ').outerHeight() ){
 

    jQuery('.cont_img_collect').height(jQuery('.caja_sobre_foto').outerHeight() + 100 ) ;

  }
 
if(jQuery(window).width()< 480){

 

}

/*
jQuery('.logo_brand svg').css({
  'width': jQuery('.logo_brand').width() + 'px',
    'height': jQuery('.logo_brand').height() + 'px'
  
})
*/

if(jQuery(window).width() > 768 ){

  jQuery('.p_desta').css('min-height', jQuery('.p_desta').outerWidth() );

}



var pleft = jQuery('.p_desta').offset() ;
pleft+=jQuery('.p_desta').width();

jQuery('.circulos').css('left', pleft.left ) ;






/*
function resizeGridItem(item){
  grid = document.getElementsByClassName("grid")[0];
  rowHeight = parseInt(window.getComputedStyle(grid).getPropertyValue('grid-auto-rows'));
  rowGap = parseInt(window.getComputedStyle(grid).getPropertyValue('grid-row-gap'));
  rowSpan = Math.ceil((item.querySelector('.content').getBoundingClientRect().height+rowGap)/(rowHeight+rowGap));
    item.style.gridRowEnd = "span "+rowSpan;
  
  console.log(rowSpan);
}*/

resizeAllGridItems();
//window.onload = resizeAllGridItems();
//window.addEventListener("resize", );

 


} //resize

function resizeGridItem(item){
  grid = document.getElementsByClassName("miGrid")[0];
  rowHeight = parseInt(window.getComputedStyle(grid).getPropertyValue('grid-auto-rows'));
  rowGap = parseInt(window.getComputedStyle(grid).getPropertyValue('grid-row-gap'));
  rowSpan = Math.ceil((item.querySelector('.content').getBoundingClientRect().height+rowGap)/(rowHeight+rowGap));
    item.style.gridRowEnd = "span "+rowSpan;
  
  console.log("rowSpan " + rowSpan + " rowGap "+ rowGap+" rowHeight "+rowHeight);
}


function resizeAllGridItems(){
  allItems = document.getElementsByClassName("itemGrid");
  for(x=0;x<allItems.length;x++){
    resizeGridItem(allItems[x]);
  }
}

function resizeInstance(instance){
  console.log("CARGADA!!!");
	itemGrid = instance.elements[0];
  resizeGridItem(itemGrid);
}



jQuery(window).on('resize' , function(){

  resize(); 

});

//SCROLL
// .circulos


function amountscrolled(){
  var winheight = jQuery(window).height()
  var docheight = jQuery(document).height()
  var scrollTop = jQuery(window).scrollTop()
  var trackLength = docheight - winheight
  var pctScrolled = Math.floor(scrollTop/trackLength * 100) // gets percentage scrolled (ie: 80 NaN if tracklength == 0)
 // console.log(pctScrolled + '% scrolled')
  return pctScrolled;
}

function amountscrolled2(){
  var winheight = jQuery(window).height()
  var docheight = jQuery(document).height()
  var scrollTop = jQuery(window).scrollTop()
  var trackLength = docheight - winheight
  var pctScrolled =  (scrollTop/trackLength * 100) // gets percentage scrolled (ie: 80 NaN if tracklength == 0)
  pctScrolled = pctScrolled.toFixed(2);
 // console.log(pctScrolled + '% scrolled')
  return pctScrolled;
}

jQuery(window).on('scroll', function(ev){
 

    preScroll = scrollTop;
    scrollTop = jQuery(window).scrollTop();

    var perScrolled = amountscrolled2();

    //this.console.log(perScrolled);

    jQuery('.parallax_vert').each(function(){

      /*
      console.log(jQuery(this).data('inipos'));
      console.log(jQuery(this).data('finpos'));
      var iPos = jQuery(this).data('inipos');
      var fPos = jQuery(this).data('finpos');
      var eme = (fPos-iPos)/(trackLength);
      var ene = iPos ;
      jQuery(this).data('m', eme );
      jQuery(this).data('n',  ene) ;
      */

      var tmpM = jQuery(this).data('m');
      var tmpN = jQuery(this).data('n');

      jQuery(this).css('bottom', (perScrolled*tmpM + tmpN) );
    
    });
     
    jQuery('.parallax_per').each(function(){

      

        var tmpM = jQuery(this).data('m');
        var tmpN = jQuery(this).data('n');
        var iniPerY = jQuery(this).data('inipery');

        console.log("M "+tmpM+" N "+ tmpN + " INIPERY " + iniPerY );

        var trans =jQuery(this).css('transform').split(',') ;

        var perX = trans[4];
        perX = parseFloat(perX);

        var perY =  trans[5];
        perY = parseFloat(perY);
        perY = iniPerY - (perScrolled*tmpM + tmpN) ; //perY*tmpM + tmpN;
        
        console.log("perY");
        console.log(perY);

        console.log("perX");
        console.log(perX);

      

        //(perScrolled*tmpM + tmpN)//      
        //jQuery(this).css('transform', 'translate(' + perX +','+perY +' )'  );
        jQuery(this).css('transform', 'translate(' + perX +'px ,'+perY  +'px )'  );
    
    });
     

   


    if(scrollTop > bannerHeight){

    //  $('.banner').addClass('minibar');

    //  jQuery('.banner').addClass('minibar_nu');
     // jQuery('.logo_sb').addClass('logo_affected');

     if(scrollTop > preScroll){
        // jQuery('header').css('opacity', 0.35);
     }else{
        //  jQuery('header').css('opacity', 1);
     }

  

    }else{

    //  $('.banner').removeClass('minibar');

     // jQuery('.banner').removeClass('minibar_nu');
     // jQuery('.logo_sb').removeClass('logo_affected');

    }
 
  



});